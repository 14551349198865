import React from 'react';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import { MultiLang } from '../config';
import Functions from '../functions';
import imageClick from './assets/images/click.gif';
import imageNoImage from './assets/images/no-image.gif';
import imagePreview1 from './assets/images/original-preview1.gif';
import imagePreview11 from './assets/images/original-preview11.gif';
import imagePreview12 from './assets/images/original-preview12.gif';
import imagePreview14 from './assets/images/original-preview14.gif';
import imagePreview15 from './assets/images/original-preview15.gif';
import imagePreview2 from './assets/images/original-preview2.gif';
import imagePreview3 from './assets/images/original-preview3.gif';
import imagePreview6 from './assets/images/original-preview6.gif';

interface Props {
    lang: MultiLang;
}

const BMIOriginalContents: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return <>
        <NoticeSiteHasBeenArchived lang={lang} />
        <h1>{Functions.mlang('[en]Original Contents (XooNIps Mode)[/en][ja]オリジナルコンテンツ (XooNIps Mode)[/ja]', lang)}</h1>
        <p>
            {Functions.mlang('[en]BMI-related materials (Movie, Picture, Data, Program) provided by scientists are listed. [/en][ja]研究者が提供したBMIに関連した資料(動画や画像、データ、プログラム)があります。[/ja]', lang)}
        </p>
        <ol className="BMIOriginalContents">
            <li>
                {Functions.mlang('[en]Click the following icon in order to access the original contents.[/en][ja]下のアイコンをクリックして、コンテンツにアクセスしてください。[/ja]', lang)}
                <div className="middle">
                    <a href="/researchers/downloads">
                        <img src={imageClick} alt="click" />
                        <strong style={{ fontSize: '1.424em' }}>{Functions.mlang('[en]Database of Original Contents[/en][ja]オリジナルコンテンツのデータベース[/ja]', lang)}</strong>
                    </a>
                </div>
            </li>
            <li>
                {Functions.mlang('[en]You can download the following BMI-related original contents.[/en][ja]以下のBMIに関連したオリジナルコンテンツをダウンロードできます。[/ja]', lang)}
                <table>
                    <tbody>
                        <tr>
                            <th colSpan={2}>
                                {Functions.mlang('[en]Monkey brain atlas[/en][ja]サルの脳定位座標（アトラス)[/ja]', lang)}
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <figure>
                                    <img src={imagePreview1} alt="thumbnail of original contents 1" />
                                    <figcaption>(JPEG files and html files)</figcaption>
                                </figure>
                            </td>
                            <td>
                                <div className="title">
                                    {Functions.mlang('[en]Brain sections of monkeys, stained for AChE, ER81 mRNA and Sema3E mRNA[/en][ja]AChE, ER81 mRNA ,Sema3E mRNA で染色した脳切片[/ja]', lang)}
                                </div>
                                <div className="description">
                                    {Functions.mlang('[en]Low-resolution photos of serial brain sections of monkeys, stained for several gene marker, such as AChE, ER81 and Sema3E mRNAs.[/en][ja]AchE(アセチルコリンエステラーゼ)、ER81 mRNA、Sema3E mRNAによるサルの脳切片画像。[/ja]', lang)}

                                </div>
                                <div className="author">
                                    {Functions.mlang('[en]Division of Brain Biology, National Institute for Basic Biology[/en][ja]基礎生物学研究所 脳生物学研究部門[/ja]', lang)}
                                    <br />
                                    {Functions.mlang('[en]Yamamori Tetsuo, Akiya Watkabe[/en][ja]山森 哲雄 先生、渡我部 昭哉 先生[/ja]', lang)}
                                </div>
                                <div className="link">
                                    <a href="http://www.nibb.ac.jp/~divspe1/" target="_blank" rel="noopener noreferrer">Web</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <figure>
                                    <img src={imagePreview2} alt="thumbnail of original contents 2" />
                                    <figcaption>(Power Point file)</figcaption>
                                </figure>
                            </td>
                            <td>
                                <div className="title">
                                    {Functions.mlang('[en]MRI Brain Atlas of Japanese "Snow" Monkey (Macaca Fuscata) at different ages[/en][ja]サルの年齢別脳アトラス[/ja]', lang)}
                                </div>
                                <div className="description">
                                    {Functions.mlang('[en]MRI brain atras of monkey from 2 to 5 ages.[/en][ja]マカクサルの年齢別(2～5歳)におけるMRI画像。[/ja]', lang)}
                                </div>
                                <div className="author">
                                    {Functions.mlang('[en]Tokyo Medical and Dental University[/en][ja]東京医科歯科大学 大学院医歯学総合研究科[/ja]', lang)}
                                    <br />
                                    {Functions.mlang('[en]Masato TAIRA[/en][ja]泰羅 雅登 先生[/ja]', lang)}
                                </div>
                                <div className="link">
                                    <a href="http://www.tmd.ac.jp/grad/cnb/cnb-J.htm" target="_blank" rel="noopener noreferrer">Web</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <figure>
                                    <img src={imagePreview3} alt="thumbnail of original contents 3" />
                                    <figcaption>(JPEG files, TIF files)</figcaption>
                                </figure>
                            </td>
                            <td>
                                <div className="title">
                                    {Functions.mlang('[en]The Stereotaxic MRI Brain Atlas of Japanese "Snow" Monkey[/en][ja]サルの方位別脳アトラス[/ja]', lang)}
                                </div>
                                <div className="description">
                                    {Functions.mlang('[en]MRI brain atras of monkey about sagittal, horizontal, and coronal plane.[/en][ja]マカクサルの矢状面、水平面、冠状面別（方位ごと）におけるMRI画像。[/ja]', lang)}
                                </div>
                                <div className="author">
                                    {Functions.mlang('[en]Tokyo Medical and Dental University[/en][ja]東京医科歯科大学 大学院医歯学総合研究科[/ja]', lang)}
                                    <br />
                                    {Functions.mlang('[en]Masato TAIRA[/en][ja]泰羅 雅登 先生[/ja]', lang)}
                                </div>
                                <div className="link">
                                    <a href="http://www.tmd.ac.jp/grad/cnb/cnb-J.htm" target="_blank" rel="noopener noreferrer">Web</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>
                                {Functions.mlang('[en]Monkey M1 BMI[/en][ja]運動出力型BMIに関係するプログラム・動画[/ja]', lang)}
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <figure>
                                    <img src={imageNoImage} alt="thumbnail of original contents 4" />
                                    <figcaption>Program (Matlab file)</figcaption>
                                </figure>
                            </td>
                            <td>
                                <div className="title">
                                    {Functions.mlang('[en]m-file for checking the results of wrist angle estimation[/en][ja]手首角度と筋電とを対応させるニューラルネットワーク生成用Mファイル[/ja]', lang)}
                                </div>
                                <div className="description">
                                    {Functions.mlang('[en]Program (m-file) for checking the relationship between joint angles and EMG signals using artificial neural network.[/en][ja]人工ニューラルネットワークを用いた関節角度と筋電信号の関係を求めるMファイル。[/ja]', lang)}
                                </div>
                                <div className="author">
                                    {Functions.mlang('[en]Tokyo Institute of Technology[/en][ja]東京工業大学 精密工学研究所[/ja]', lang)}
                                    <br />
                                    {Functions.mlang('[en]Yasuharu Koike[/en][ja]小池 康晴 先生[/ja]', lang)}
                                </div>
                                <div className="link">
                                    <a href="http://www.cns.pi.titech.ac.jp/kylab/" target="_blank" rel="noopener noreferrer">Web</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <figure>
                                    <img src={imageNoImage} alt="thumbnail of original contents 5" />
                                    <figcaption>Program (Matlab file)</figcaption>
                                </figure>
                            </td>
                            <td>
                                <div className="title">
                                    {Functions.mlang('[en]m-file for training of wrist angle estimation[/en][ja]手首角度推定結果確認用Mファイル[/ja]', lang)}
                                </div>
                                <div className="description">
                                    {Functions.mlang('[en]Program (m-file) to train the relationship between joint angles and EMG signals using artificial neural network.[/en][ja]人工ニューラルネットワークを用いた関節角度と筋電信号の関係を求めるMファイル。[/ja]', lang)}
                                </div>
                                <div className="author">
                                    {Functions.mlang('[en]Tokyo Institute of Technology[/en][ja]東京工業大学 精密工学研究所[/ja]', lang)}
                                    <br />
                                    {Functions.mlang('[en]Yasuharu Koike[/en][ja]小池 康晴 先生[/ja]', lang)}
                                </div>
                                <div className="link">
                                    <a href="http://www.cns.pi.titech.ac.jp/kylab/" target="_blank" rel="noopener noreferrer">Web</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <figure>
                                    <img src={imagePreview6} alt="thumbnail of original contents 6" />
                                    <figcaption>Movie</figcaption>
                                </figure>
                            </td>
                            <td>
                                <div className="title">
                                    {Functions.mlang('[en]M1 Neuronal Activity during monkey performing a motor task[/en][ja]サルに運動課題を行わせたときの一次運動野における神経活動[/ja]', lang)}
                                </div>
                                <div className="description">
                                    {Functions.mlang('[en]Movie file of neuronal activity of the primary motor cortex while the monkey perform a motor task.[/en][ja]上肢到達運動課題を遂行中のサル一次運動野のニューロン活動を撮影した映像。[/ja]', lang)}
                                </div>
                                <div className="author">
                                    {Functions.mlang('[en]Tohoku University Graduate School of Life Sciences[/en][ja]東北大学大学院 生命科学研究科 脳情報処理分野[/ja]', lang)}
                                    <br />
                                    {Functions.mlang('[en]Toshio Iijima[/en][ja]飯島 敏夫 先生[/ja]', lang)}
                                </div>
                                <div className="link">
                                    <a href="http://www.biology.tohoku.ac.jp/lab-www/iijimalab/" target="_blank" rel="noopener noreferrer">Web</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <figure>
                                    <img src={imageNoImage} alt="thumbnail of original contents 7" />
                                    <figcaption>Program (Matlab file)</figcaption>
                                </figure>
                            </td>
                            <td>
                                <div className="title">
                                    {Functions.mlang('[en]Muscle tension[/en][ja]筋張力推定用M-ファイル[/ja]', lang)}
                                </div>
                                <div className="description">
                                    {Functions.mlang('[en]Program (m-file) to estimate muscle tension from raw emg signal.[/en][ja]筋電信号から筋張力を推定するためのMファイル。[/ja]', lang)}
                                </div>
                                <div className="author">
                                    {Functions.mlang('[en]Tokyo Institute of Technology[/en][ja]東京工業大学 精密工学研究所[/ja]', lang)}
                                    <br />
                                    {Functions.mlang('[en]Yasuharu Koike[/en][ja]小池 康晴 先生[/ja]', lang)}
                                </div>
                                <div className="link">
                                    <a href="http://www.cns.pi.titech.ac.jp/kylab/" target="_blank" rel="noopener noreferrer">Web</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <figure>
                                    <img src={imageNoImage} alt="thumbnail of original contents 8" />
                                    <figcaption>Program (Matlab file)</figcaption>
                                </figure>
                            </td>
                            <td>
                                <div className="title">
                                    {Functions.mlang('[en]raw EMG signal[/en][ja]筋電信号のデータ[/ja]', lang)}
                                </div>
                                <div className="description">
                                    {Functions.mlang('[en]Raw EMG signal for 5 seconds. [/en][ja]5秒間の筋電信号。Mファイルで開く。[/ja]', lang)}
                                </div>
                                <div className="author">
                                    {Functions.mlang('[en]Tokyo Institute of Technology[/en][ja]東京工業大学 精密工学研究所[/ja]', lang)}
                                    <br />
                                    {Functions.mlang('[en]Yasuharu Koike[/en][ja]小池 康晴 先生[/ja]', lang)}
                                </div>
                                <div className="link">
                                    <a href="http://www.cns.pi.titech.ac.jp/kylab/" target="_blank" rel="noopener noreferrer">Web</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <figure>
                                    <img src={imageNoImage} alt="thumbnail of original contents 9" />
                                    <figcaption>Program (Matlab file)</figcaption>
                                </figure>
                            </td>
                            <td>
                                <div className="title">
                                    {Functions.mlang('[en]training data of wrist angle and emg signal [/en][ja]手首の角度と筋電信号の訓練データ?[/ja]', lang)}
                                </div>
                                <div className="description">
                                    {Functions.mlang('[en]Training data to learn artificial neural network.[/en][ja]推定に用いる手首角度と筋電信号の訓練データ（Mファイル）。[/ja]', lang)}
                                </div>
                                <div className="author">
                                    {Functions.mlang('[en]Tokyo Institute of Technology[/en][ja]東京工業大学 精密工学研究所[/ja]', lang)}
                                    <br />
                                    {Functions.mlang('[en]Yasuharu Koike[/en][ja]小池 康晴 先生[/ja]', lang)}
                                </div>
                                <div className="link">
                                    <a href="http://www.cns.pi.titech.ac.jp/kylab/" target="_blank" rel="noopener noreferrer">Web</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <figure>
                                    <img src={imageNoImage} alt="thumbnail of original contents 10" />
                                    <figcaption>Program (Matlab file)</figcaption>
                                </figure>
                            </td>
                            <td>
                                <div className="title">
                                    {Functions.mlang('[en]weight file of neural network[/en][ja]ニューラルネットワークの重みデータ[/ja]', lang)}
                                </div>
                                <div className="description">
                                    {Functions.mlang('[en]Weight data generated by artificial neural network. [/en][ja]生成されたニューラルネットワークの重みデータ（Mファイル）。 [/ja]', lang)}
                                </div>
                                <div className="author">
                                    {Functions.mlang('[en]Tokyo Institute of Technology[/en][ja]東京工業大学 精密工学研究所[/ja]', lang)}
                                    <br />
                                    {Functions.mlang('[en]Yasuharu Koike[/en][ja]小池 康晴 先生[/ja]', lang)}
                                </div>
                                <div className="link">
                                    <a href="http://www.cns.pi.titech.ac.jp/kylab/" target="_blank" rel="noopener noreferrer">Web</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>
                                {Functions.mlang('[en]Multineuron activity in monkey prefrontal cortex[/en][ja]サルの前頭前野における神経活動[/ja]', lang)}
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <figure>
                                    <img src={imagePreview11} alt="thumbnail of original contents 11" />
                                    <figcaption>Movie (avi file)</figcaption>
                                </figure>
                            </td>
                            <td>
                                <div className="title">
                                    {Functions.mlang('[en]Multineuron activity in monkey prefrontal cortex recorded by 3 tetrodes.[/en][ja]サルの前頭前野における神経活動[/ja]', lang)}
                                </div>
                                <div className="description">
                                    {Functions.mlang('[en]Movie of multineuron activity in monkey prefrontal cortex recorded by 3 tetrodes.[/en][ja]テトロードにより記録されたサルの前頭前野における神経活動の動画。[/ja]', lang)}
                                </div>
                                <div className="author">
                                    {Functions.mlang('[en]Faulty of Letters, Kyoto University[/en][ja]京都大学 大学院文学研究科 心理学研究室[/ja]', lang)}
                                    <br />
                                    {Functions.mlang('[en]Yoshio Sakurai[/en][ja]櫻井 芳雄 先生[/ja]', lang)}
                                </div>
                                <div className="link">
                                    <a href="http://www.bun.kyoto-u.ac.jp/~ysakurai/" target="_blank" rel="noopener noreferrer">Web</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>
                                {Functions.mlang('[en]Cortical Box Method[/en][ja]新規解析プログラム[/ja]', lang)}
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <figure>
                                    <img src={imagePreview12} alt="thumbnail of original contents 12" />
                                    <figcaption>Program</figcaption>
                                </figure>
                            </td>
                            <td>
                                <div className="title">
                                    {Functions.mlang('[en]Cortical Box Method[/en][ja]新規解析プログラム[/ja]', lang)}
                                </div>
                                <div className="description">
                                    {Functions.mlang('[en]An analytical method that standardizes the serial coronal sections of rodent cortex for quantitative analysis[/en][ja]齧歯類の大脳皮質の連続冠状断面を定量的に分析するための新規プログラム。[/ja]', lang)}
                                </div>
                                <div className="author">
                                    {Functions.mlang('[en]Division of Brain Biology, National Institute for Basic Biology[/en][ja]基礎生物学研究所 脳生物学研究部門[/ja]', lang)}
                                    <br />
                                    {Functions.mlang('[en]Tetsuo Yamamori, Junya Hirokawa[/en][ja]山森 哲雄 先生、廣川 純也 先生[/ja]', lang)}
                                </div>
                                <div className="link">
                                    <a href="http://www.nibb.ac.jp/~divspe1/" target="_blank" rel="noopener noreferrer">Web</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>
                                {Functions.mlang('[en]fMRI visual image reconstruction[/en][ja]fMRIによる視覚映像の再構築 [/ja]', lang)}
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <figure>
                                    <img src={imageNoImage} alt="thumbnail of original contents 13" />
                                    <figcaption>URL</figcaption>
                                </figure>
                            </td>
                            <td>
                                <div className="title">
                                    {Functions.mlang('[en]fMRI data and program for visual image reconstruction[/en][ja]視覚映像を再構築するためのfMRIデータとプログラム?[/ja]', lang)}
                                </div>
                                <div className="description">
                                    {Functions.mlang('[en]Link to the website where fMRI data and program can be downloaded.[/en][ja]視覚映像を再構築したfMRIのデータとプログラムをダウンロードできるサイトへのリンク。[/ja]', lang)}
                                </div>
                                <div className="author">
                                    {Functions.mlang('[en]ATR Department of Neuroinformatics[/en][ja]ATR 脳情報研究所 神経情報学研究室[/ja]', lang)}
                                    <br />
                                    {Functions.mlang('[en]Yukiyasu Kamitani, Yoichi Miyawaki[/en][ja]神谷 之康 先生、宮脇 陽一 先生[/ja]', lang)}
                                </div>
                                <div className="link">
                                    <a href="http://www.cns.atr.jp/dni/" target="_blank" rel="noopener noreferrer">Web</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <figure>
                                    <img src={imagePreview14} alt="thumbnail of original contents 14" />
                                    <figcaption>(Power Point file)</figcaption>
                                </figure>
                            </td>
                            <td>
                                <div className="title">
                                    {Functions.mlang('[en]Reconstructed visual images from human fMRI activity[/en][ja]fMRIを用いたヒトの視覚映像の再構築[/ja]', lang)}
                                </div>
                                <div className="description">
                                    {Functions.mlang('[en]Visual images defined by binary contrast patterns consisting of 10x10 square patches were reconstructed from human fMRI activity.[/en][ja]fMRIを用いてヒトの視覚映像を再構築した画像。[/ja]', lang)}
                                </div>
                                <div className="author">
                                    {Functions.mlang('[en]ATR Department of Neuroinformatics[/en][ja]ATR 脳情報研究所 神経情報学研究室[/ja]', lang)}
                                    <br />
                                    {Functions.mlang('[en]Yukiyasu Kamitani, Yoichi Miyawaki[/en][ja]神谷 之康 先生、宮脇 陽一 先生[/ja]', lang)}
                                </div>
                                <div className="link">
                                    <a href="http://www.cns.atr.jp/dni/" target="_blank" rel="noopener noreferrer">Web</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>
                                {Functions.mlang('[en]Monkey M1 & SMA single-unit activity[/en][ja]サルの一次運動野と補足運動野における単一神経活動[/ja]', lang)}
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <figure>
                                    <img src={imagePreview15} alt="thumbnail of original contents 15" />
                                    <figcaption>(Power Point file)</figcaption>
                                </figure>
                            </td>
                            <td>
                                <div className="title">
                                    {Functions.mlang('[en]Neuronal activity during performance of a memory-guided movement[/en][ja]記憶誘導性運動時の神経活動[/ja]', lang)}
                                </div>
                                <div className="description">
                                    {Functions.mlang('[en]Result of M1and SMA activity during performed one of four possible movements in a memory-guided manner.[/en][ja]記憶誘導性動作課題における一次運動野と補足運動野からの神経活動計測の結果。[/ja]', lang)}
                                </div>
                                <div className="author">
                                    {Functions.mlang('[en]Department of Physiology, Tohoku University School of Medicine[/en][ja]東北大学大学院 医学系研究科 生体システム生理学講座[/ja]', lang)}
                                    <br />
                                    {Functions.mlang('[en]Toshi Nakajima, Hajime Mushiake[/en][ja]中島 敏 先生、虫明 元 先生[/ja]', lang)}
                                </div>
                                <div className="link">
                                    <a href="http://www.neurophysiology.med.tohoku.ac.jp/" target="_blank" rel="noopener noreferrer">Web</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <figure>
                                    <img src={imageNoImage} alt="thumbnail of original contents 16" />
                                    <figcaption>Raw data (Excel file)</figcaption>
                                </figure>
                            </td>
                            <td>
                                <div className="title">
                                    {Functions.mlang('[en]Neuronal activity during performance of a memory-guided movement[/en][ja]記憶誘導性運動時の神経活動データ[/ja]', lang)}
                                </div>
                                <div className="description">
                                    {Functions.mlang('[en]Result of M1 and SMA activity during performed one of four possible movements in a memory-guided manner.[/en][ja]記憶誘導性動作課題における一次運動野と補足運動野からの神経活動計測のデータ。[/ja]', lang)}
                                </div>
                                <div className="author">
                                    {Functions.mlang('[en]Department of Physiology, Tohoku University School of Medicine[/en][ja]東北大学大学院 医学系研究科 生体システム生理学講座[/ja]', lang)}
                                    <br />
                                    {Functions.mlang('[en]Toshi Nakajima, Hajime Mushiake[/en][ja]中島 敏 先生、虫明 元 先生[/ja]', lang)}
                                </div>
                                <div className="link">
                                    <a href="http://www.neurophysiology.med.tohoku.ac.jp/" target="_blank" rel="noopener noreferrer">Web</a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </li>
        </ol>
    </>;
}

export default BMIOriginalContents;