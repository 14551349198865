import React, { Component, Fragment } from 'react';
import { Modal } from 'react-overlays';
import { RouteComponentProps, withRouter } from 'react-router';
import DraggablePanel from '../../common/lib/DraggablePanel';
import ResearchInterestIcon from './ResearchInterestIcon';
import { ResearchSiteData, ResearchSiteRegion } from './ResearchSitesUtil';

interface Props extends RouteComponentProps {
    region: ResearchSiteRegion;
    site: ResearchSiteData;
}

interface ResearcherIdLinkProps {
    type: string;
    id: string;
}
const ResearcherIdLink: React.FC<ResearcherIdLinkProps> = (props: ResearcherIdLinkProps) => {
    const { type, id } = props;
    let url = '';
    let title = ''
    switch (type) {
        case 'scopus':
            url = 'https://www.scopus.com/authid/detail.url?authorId=' + id;
            title = 'Scopus';
            break;
        case 'researcherid':
            url = 'https://publons.com/researcher/' + id + '/';
            title = 'ResearcherID';
            break;
        case 'researchmap':
            url = 'https://researchmap.jp/' + id;
            title = 'Researchmap';
            break;
    }
    return <a href={url} target="_blank" rel="noopener noreferrer">{title}</a>
};

class ResearchLocationMapSite extends Component<Props> {

    constructor(props: Props) {
        super(props);
        this.handleClickClose = this.handleClickClose.bind(this);
    }

    handleClickClose() {
        const { region, history } = this.props;
        const url = '/research/location/' + region;
        history.push(url);
    }

    renderBackdrop(props: any) {
        return <div className="BMIResearchSitesLocationModalOverlay" {...props} />;
    }

    render() {
        const { site } = this.props;
        return <Modal className="BMIResearchSitesLocationModal" show={true} onHide={this.handleClickClose} renderBackdrop={this.renderBackdrop}>
            <div className="panel siteDetail">
                <div className="close" onClick={this.handleClickClose} title="close">&times;</div>
                <h1>{site.name}</h1>
                <hr />
                <DraggablePanel className="detail">
                    {site.laboratories.map((lab, index) => {
                        const key = site.link + index;
                        return <Fragment key={key}>
                            {index > 0 && <hr />}
                            <div className="laboratry">
                                <h2>{lab.name}</h2>
                                <div className="researchers">
                                    {lab.persons.map((person) => {
                                        return <div key={person.name} className="person">
                                            <h3>
                                                <span className="name">{person.name}</span>{person.title !== '' && ', ' + person.title}
                                            </h3>
                                            <div className="ids">
                                                {person.ids.map((pid, index) => {
                                                    return <Fragment key={pid.type}>
                                                        {index === 0 ? <> &raquo; </> : ', '}
                                                        <ResearcherIdLink type={pid.type} id={pid.id} />
                                                    </Fragment>;
                                                })}
                                            </div>
                                        </div>;
                                    })}
                                </div>
                                <div className="link">
                                    <a href={lab.url} target="_blank" rel="noopener noreferrer">Go to Web</a>
                                </div>
                                <div className="division">{lab.division}</div>
                                <div className="address">{lab.address}</div>
                                <div className="phone">{lab.phone}</div>
                                {lab.items.length > 0 && <div className="publications">
                                    <div className="title"><strong>BMI-related publications</strong> (links to database)</div>
                                    <ul>
                                        {lab.items.map((item) => {
                                            const link = '/researchers/database/item/' + item.item_id;
                                            return <li key={item.item_id}>
                                                <a href={link}>{item.title}</a>
                                            </li>;
                                        })}
                                    </ul>
                                </div>}
                                {lab.interests.length > 0 && <div className="interests">
                                    {lab.interests.map((interest, index) => {
                                        return <Fragment key={interest}>
                                            {index > 0 && ' '}
                                            <ResearchInterestIcon type={interest} />
                                        </Fragment>;
                                    })}
                                </div>}

                            </div>
                        </Fragment>;
                    })}
                </DraggablePanel>
            </div>
        </Modal >;
    }
}

export default withRouter(ResearchLocationMapSite);
