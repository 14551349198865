import React from 'react';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import { MultiLang } from '../config';
import Functions from '../functions';
import imageBannerINCFJapanNode from './assets/images/banner_incf-japan-node.gif';
import imageBannerCBS from './assets/images/banner_riken-cbs.gif';
import imageBannerSRPBS from './assets/images/banner_srpbs.gif';
import imageBannerXooNIps from './assets/images/banner_xoonips.gif';
import imageCover from './assets/images/bmi-cover.gif';
import imageIconData from './assets/images/icon_data.gif';
import imageIconFiles from './assets/images/icon_files.gif';
import imageIconFolder from './assets/images/icon_folder.gif';
import imageIconPaper from './assets/images/icon_paper.gif';
import imageIconWorld from './assets/images/icon_world.gif';
import { Link } from 'react-router-dom';

interface Props {
    lang: MultiLang;
}

const BMIIndex: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return <>
        <NoticeSiteHasBeenArchived lang={lang} />
        <h1>{Functions.mlang('[en]Welcome to BMI-PF[/en][ja]BMIプラットフォームとは[/ja]', lang)}</h1>
        <p>
            <strong>
                {Functions.mlang('[en]Brain Machine Interface Platform (BMI PF) accumulates BMI(Brain Machine Interface)-related data that are useful for education and experiment.[/en][ja]Brain Machine Interface platform (BMI-PF) では、教育や研究に活かせるように、BMI(Brain Machine Interface)に関連したデータを蓄積しています。[/ja]', lang)}
            </strong>
        </p>
        <p>
            {Functions.mlang('[en]The development of this site has been completed in FY2016.[/en][ja]当サイトは、2016年度で開発を完了しました。[/ja]', lang)}
        </p>
        <div className="clearfix">
            <div className="left">
                <div>
                    <div className="middle">
                        <Link to="/paper"><img src={imageIconPaper} alt="paper icon" /><strong>1. {Functions.mlang('[en]Database of BMI (Brain Machine Interfaces)-related papers[/en][ja]BMIに関連した論文のデータベース[/ja]', lang)}</strong></Link>
                    </div>
                    <p>
                        {Functions.mlang('[en]More than 3500 BMI-related papers are registered. Each paper has original tags, for example, recording method and subject, for easy searching.[/en][ja]3500本以上のＢＭＩに関連した論文が登録されています。各論文には、レコーディング方法やサブジェ>クト等のタグがついており、検索が容易となっています。[/ja]', lang)}
                    </p>
                </div>
                <div>
                    <div className="middle">
                        <Link to="/original"><img src={imageIconFolder} alt="fonder icon" /><strong>2. {Functions.mlang('[en]Database of original contents[/en][ja]オリジナルコンテンツのデータベース[/ja]', lang)}</strong></Link>
                    </div>
                    <p>
                        {Functions.mlang('[en]BMI-related materials (Movie, Picture, Data, Program) provided by scientists are listed.[/en][ja]研究者が提供したBMIに関連した資料(動画や画像、データ、プログラム)があります。[/ja]', lang)}
                    </p>
                </div>
                <div>
                    <div className="middle">
                        <Link to="/research"><img src={imageIconWorld} alt="world icon" /><strong>3. {Functions.mlang('[en]Database of BMI-related research sites[/en][ja]BMIに関連した研究室検索のデータベース[/ja]', lang)}</strong></Link>
                    </div>
                    <p>
                        {Functions.mlang('[en]185 BMI-related research sites in the world (univescity, institute and company) are registered.The research site can be searched either by the location using clickable map or by the field of interest.[/en][ja]185もの大学や研究機関、企業のBMIに>関連したホームページが登録されています。研究室検索では、地図をクリックすることで、地域または分野から研究室を検索することが可能です。[/ja]', lang)}
                    </p>
                </div>
                <div>
                    <div className="middle">
                        <Link to="/materials"><img src={imageIconData} alt="data icon" /><strong>4. {Functions.mlang('[en]Database of BMI-related materials[/en][ja]BMIに関連した資料のデータベース[/ja]', lang)}</strong></Link>
                    </div>
                    <p>
                        {Functions.mlang('[en]Links to BMI-related materials (Movie, Picture, Document, Data, Program) are listed. You can easily find materials of your interest since each material is classified into research field.[/en][ja]BMIに関連した資料(動画や画像、ドキュメント、デー>タ、プログラム)へのリンクの一覧です。各資料は検索分野で分類されており、関心のある資料を容易に見つけることが可能です。[/ja]', lang)}
                    </p>
                </div>
                <div>
                    <div className="middle">
                        <Link to="/column"><img src={imageIconFiles} alt="files icon" /><strong>5. {Functions.mlang('[en]BMI-related column (Japanese)[/en][ja]BMIに関連したコラム[/ja]', lang)}</strong></Link>
                    </div>
                    <p>
                        {Functions.mlang('[en]The columns are written by researches specialized in BMI.[/en][ja]BMIを専門とする研究者によるコラムがあります。[/ja]', lang)}
                    </p>
                </div>
            </div>
            <div className="right">
                <img src={imageCover} alt="bmi cover logo" />
            </div>
        </div>

        <h1>{Functions.mlang('[en]Site Information[/en][ja]サイト情報[/ja]', lang)}</h1>
        <ul>
            <li><Link to="/instructions">{Functions.mlang('[en]Instructions for use[/en][ja]ご利用上の注意[/ja]', lang)}</Link></li>
            <li><Link to="/copyrights">{Functions.mlang('[en]Copyright[/en][ja]著作権について[/ja]', lang)}</Link></li>
            <li><Link to="/privacy">{Functions.mlang('[en]Privacy policy[/en][ja]個人情報の取扱いについて[/ja]', lang)}</Link></li>
            <li><Link to="/aboutus">{Functions.mlang('[en]About us[/en][ja]運用母体[/ja]', lang)}</Link></li>
        </ul>

        <h1>{Functions.mlang('[en]Links[/en][ja]リンク[/ja]', lang)}</h1>
        {lang === 'en' ?
            <p>This site is a part of <a href="https://www.neuroinf.jp/" target="_blank" rel="noopener noreferrer">INCF Japan Node</a>, and is powered by <a href="http://xoonips.osdn.jp/" target="_blank" rel="noopener noreferrer">XooNIps</a>. The server for this site is located in <a href="https://cbs.riken.jp" target="_blank" rel="noopener noreferrer">RIKEN Center for Brain Science</a>.</p> :
            <p>このホームページは <a href="https://www.neuroinf.jp/" target="_blank" rel="noopener noreferrer">INCF 日本ノード</a> の一部であり、 <a href="http://xoonips.osdn.jp/" target="_blank" rel="noopener noreferrer">XooNIps</a> を使用しています。また、サーバーは <a href="https://cbs.riken.jp" target="_blank" rel="noopener noreferrer">理化学研究所 脳神経学研究センター</a> で運用されています。</p>
        }
        <p>
            <a href="https://www.neuroinf.jp/" target="_blank" rel="noopener noreferrer"><img src={imageBannerINCFJapanNode} alt="INCF Japan Node" /></a>
            <a href="https://xoonips.osdn.jp/" target="_blank" rel="noopener noreferrer"><img src={imageBannerXooNIps} alt="XooNIps Offical Site" /></a>
            <a href="https://cbs.riken.jp" target="_blank" rel="noopener noreferrer"><img src={imageBannerCBS} alt="RIKEN Center for Brain Science" /></a>
        </p>
        <p>
            <a href="http://www.nips.ac.jp/srpbs/" target="_blank" rel="noopener noreferrer"><img src={imageBannerSRPBS} alt="Strategic Research Program for Brain Sciences (SRPBS)" /></a>
        </p>
        <ul>
            <li>
                <a href="http://www.nips.ac.jp/srpbs/missionA/" target="_blank" rel="noopener noreferrer">{Functions.mlang('[en]Mission A[/en][ja]課題A[/ja]', lang)}</a>
                <p>
                    {Functions.mlang('[en]Mission A promotes the development of BMI which enable us to understand brain function and to assist or repair human sensory-motor functions by decoding and controlling brain signals.[/en][ja]「研究開発拠点整備事業（課題A）」では、脳内情報を解読>・制御することにより、脳機能を理解するとともに脳機能や身 体機能の回復・補完を可能とする　ブレイン・マシン・インターフェース（BMI）の開発を推進しています。[/ja]', lang)}
                </p>
            </li>
            <li>
                <a href="http://www.nips.ac.jp/srpbs/missionB/" target="_blank" rel="noopener noreferrer">{Functions.mlang('[en]Mission B[/en][ja]課題B[/ja]', lang)}</a>
                <p>
                    {Functions.mlang('[en]Mission B promotes the basic research or the development of related technique which complements subject A.[/en][ja]「個別研究事業（課題Ｂ）」では、課題Aにおける研究を補完する要素的研究や関連技術の開発を推進しています。[/ja]', lang)}
                </p>
            </li>
        </ul>
    </>;
}

export default BMIIndex;