const SITE_TITLE = '[en]BMI Platform[/en][ja]BMIプラットフォーム[/ja]';
const SITE_SLOGAN = 'Neuroinformatics Platform';
const GOOGLE_ANALYTICS_TRACKING_ID = 'UA-2787315-1';

export type MultiLang = 'en' | 'ja';

const Config = {
    SITE_TITLE,
    SITE_SLOGAN,
    GOOGLE_ANALYTICS_TRACKING_ID,
}

export default Config;
