import Tree from 'rc-tree';
import { DataNode, EventDataNode } from 'rc-tree/lib/interface';
import React, { Component, ReactText } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { MultiLang } from '../../config';
import './MaterialsTree.css';
import MaterialsUtil, { MaterialTreeData } from './MaterialsUtil';

interface Props extends RouteComponentProps {
    lang: MultiLang;
    selectedKey: string;
}

interface State {
    tree: DataNode[];
    expandableKeys: string[];
    expandedKeys: string[];
    selectedKeys: string[];
}

class MaterialsTree extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.handleExpand = this.handleExpand.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        const res = this.load();
        this.state = {
            tree: res.elements,
            expandableKeys: res.keys,
            expandedKeys: res.expandedKeys,
            selectedKeys: res.selectedKeys,
        };
    }

    load() {
        const { selectedKey } = this.props;
        let keys: string[] = [];
        let eKeys: string[] = [];
        let sKeys: string[] = [];
        const makeTreeNode = (data: MaterialTreeData, depth: number): DataNode => {
            const children = MaterialsUtil.getChildTreeNodes(data.id);
            if (selectedKey === data.name) {
                sKeys.push(data.name);
            }
            if (children.length === 0) {
                return { key: data.name, title: data.title, isLeaf: true };
            }
            const childTreeNodes = children.map((value: MaterialTreeData) => {
                return makeTreeNode(value, depth + 1);
            });
            if (depth < 1) {
                eKeys.push(data.name);
            }
            keys.push(data.name);
            return {
                key: data.name,
                title: data.title,
                children: childTreeNodes,
            };
        };
        let elements: DataNode[] = [];
        const children = MaterialsUtil.getChildTreeNodes(0);
        children.forEach((child) => {
            elements.push(makeTreeNode(child, 0));
        })
        return {
            elements: elements,
            keys: keys,
            expandedKeys: eKeys,
            selectedKeys: sKeys,
        }
    }

    handleExpand(expandedKeys: ReactText[], info: {
        node: EventDataNode;
        expanded: boolean;
        nativeEvent: MouseEvent;
    }): void {
        const keys: string[] = expandedKeys.map((key) => {
            return typeof key === 'string' ? key : String(key);
        });
        this.setState({ expandedKeys: keys });
    }

    handleSelect(selectedKeys: ReactText[], info: {
        event: 'select';
        selected: boolean;
        node: EventDataNode;
        selectedNodes: DataNode[];
        nativeEvent: MouseEvent;
    }): void {
        const { history } = this.props;
        const selectedKey = String(selectedKeys.shift() || '');
        const { isLeaf = false } = info.node;
        if (isLeaf) {
            if (selectedKey !== '' && !this.state.selectedKeys.includes(selectedKey)) {
                const url = '/materials/picture/' + selectedKey;
                history.push(url);
                this.setState({ selectedKeys: [selectedKey] });
            }
        } else {
            if (this.state.expandedKeys.includes(selectedKey)) {
                this.setState({ expandedKeys: this.state.expandedKeys.filter((key) => { return key !== selectedKey; }) });
            } else {
                this.setState({ expandedKeys: this.state.expandedKeys.concat(selectedKey) });
            }
        }
    }

    render() {
        return <Tree className="tree" expandedKeys={this.state.expandedKeys} selectedKeys={this.state.selectedKeys} onExpand={this.handleExpand} onSelect={this.handleSelect} showLine={true} treeData={this.state.tree} />;
    }
}

export default withRouter(MaterialsTree);