import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import { MultiLang } from '../config';
import Functions from '../functions';
import imageWorldmap from './assets/images/worldmap.gif';
import ResearchLocationMap from './lib/ResearchLocationMap';
import { ResearchSiteRegion } from './lib/ResearchSitesUtil';

interface Props extends RouteComponentProps {
    lang: MultiLang;
    region?: ResearchSiteRegion;
    siteId?: number;
}

interface State {
    showModalMap: boolean;
    showModalLocation: boolean;
}


class BMIResearchSitesLocation extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showModalMap: false,
            showModalLocation: false,
        };
        this.handleClickArea = this.handleClickArea.bind(this);
    }

    handleClickArea(e: React.MouseEvent<HTMLAreaElement, MouseEvent>) {
        const { history } = this.props;
        const region = e.currentTarget.getAttribute('href') || '';
        const link = '/research/location/' + region;
        e.preventDefault();
        e.stopPropagation();
        history.push(link);
    }

    render() {
        const { lang, region, siteId } = this.props;
        return <div className="BMIResearchSitesLocation">
            <NoticeSiteHasBeenArchived lang={lang} />
            <h1>{Functions.mlang('[en]Research Sites[/en][ja]BMI関連研究室検索[/ja]', lang)}</h1>
            <Link to="/research">TOP</Link>
            <h2>{Functions.mlang('[en]Search by the location[/en][ja]地図から検索[/ja]', lang)}</h2>
            <div className="worldmap">
                <img src={imageWorldmap} alt="World Map" useMap="#World" />
                <map name="World">
                    <area shape="rect" coords="16,100,131,128" onClick={this.handleClickArea} href="europe" alt="Europe" title="Europe" />
                    <area shape="rect" coords="100,170,295,198" onClick={this.handleClickArea} href="middle-east" alt="Middle East" title="Middle East" />
                    <area shape="rect" coords="293,142,447,170" onClick={this.handleClickArea} href="east-asia" alt="East Asia" title="East Asia" />
                    <area shape="rect" coords="296,323,429,351" onClick={this.handleClickArea} href="oceania" alt="Oceania" title="Oceania" />
                    <area shape="rect" coords="542,129,794,157" onClick={this.handleClickArea} href="north-america" alt="North America" title="North America" />
                </map>
            </div>
            {region && <ResearchLocationMap lang={lang} region={region} siteId={siteId} />}
        </div>;
    }
}

export default withRouter(BMIResearchSitesLocation);