import React from 'react';
import { Link } from 'react-router-dom';
import DraggablePanel from '../../common/lib/DraggablePanel';
import { ResearchSiteData } from './ResearchSitesUtil';

interface Props {
    sites: ResearchSiteData[];
}

export const ResearchLocationMapList: React.FC<Props> = (props: Props) => {
    const { sites } = props;
    const siteList = new Map<string, ResearchSiteData[]>();
    const countryList: string[] = [];
    sites.forEach((site) => {
        const country = site.country;
        if (!siteList.has(country)) {
            countryList.push(country);
        }
        const sites: ResearchSiteData[] = siteList.get(country) || [];
        sites.push(site);
        siteList.set(country, sites);
    });
    const getSiteList = (country: string) => {
        const sites = siteList.get(country);
        if (typeof sites === 'undefined') {
            return null;
        }
        return sites.map((site) => {
            return <li key={site.name}>
                <Link to={'/research/location/' + site.link}>{site.name}</Link>
            </li>;
        });
    };
    return <DraggablePanel className="list">
        <ul>
            {countryList.map((country) => {
                return <li key={country}>
                    <div className="country">{country}</div>
                    <ul>
                        {getSiteList(country)}
                    </ul>
                </li>;
            })}
        </ul>
    </DraggablePanel>;
};

export default ResearchLocationMapList;