import React from 'react';
import { Link } from 'react-router-dom';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import { MultiLang } from '../config';
import Functions from '../functions';
import ResearchInterestIcon from './lib/ResearchInterestIcon';

interface Props {
    lang: MultiLang;
}

const BMIResearchSitesInterest: React.FC<Props> = (props: Props) => {
    const { lang } = props;

    return <div className="BMIResearchSitesInterest">
        <NoticeSiteHasBeenArchived lang={lang} />
        <h1>{Functions.mlang('[en]Research Sites[/en][ja]BMI関連研究室検索[/ja]', lang)}</h1>
        <Link to="/research">TOP</Link>
        <h2>{Functions.mlang('[en]Search by the field of interest[/en][ja]分野から検索[/ja]', lang)}</h2>
        <div className="clearfix">
            <div className="interest">
                <div className="title">Subjects</div>
                <div className="subTitle">&nbsp;</div>
                <div className="icon"><ResearchInterestIcon type="human" /> - human</div>
                <div className="icon"><ResearchInterestIcon type="monkey" /> - monkey</div>
                <div className="icon"><ResearchInterestIcon type="rodent" /> - rodent</div>
                <div className="icon"><ResearchInterestIcon type="other-animals" /> - other animals</div>
            </div>
            <div className="interest">
                <div className="title">Physiology</div>
                <div className="subTitle">- Motor</div>
                <div className="icon"><ResearchInterestIcon type="physiology-arm" /> - arm</div>
                <div className="icon"><ResearchInterestIcon type="physiology-hand" /> - hand</div>
                <div className="icon"><ResearchInterestIcon type="physiology-locomotion" /> - locomotion</div>
                <div className="icon"><ResearchInterestIcon type="physiology-whole-body" /> - whole body movement</div>
                <div className="icon"><ResearchInterestIcon type="physiology-navigation" /> - navigation</div>
                <div className="subTitle">- Sensory</div>
                <div className="icon"><ResearchInterestIcon type="physiology-visual" /> - visual</div>
                <div className="icon"><ResearchInterestIcon type="physiology-auditory" /> - auditory</div>
                <div className="icon"><ResearchInterestIcon type="physiology-somatosensory" /> - somatosensory</div>
            </div>
            <div className="interest">
                <div className="title">Theories and Models</div>
                <div className="subTitle">- Signal Processing</div>
                <div className="icon"><ResearchInterestIcon type="unit-recording" /> - unit recording</div>
                <div className="icon"><ResearchInterestIcon type="emg" /> - EMG</div>
                <div className="icon"><ResearchInterestIcon type="eeg" /> - EEG</div>
                <div className="icon"><ResearchInterestIcon type="nirs" /> - NIRS</div>
                <div className="icon"><ResearchInterestIcon type="fmri" /> - fMRI</div>
                <div className="icon"><ResearchInterestIcon type="ecog" /> - ECoG</div>
                <div className="icon"><ResearchInterestIcon type="pet" /> - PET</div>
                <div className="icon"><ResearchInterestIcon type="meg" /> - MEG</div>
                <div className="icon"><ResearchInterestIcon type="kinematics" /> - kinematics</div>
                <div className="icon"><ResearchInterestIcon type="control-theories" /> - control theories</div>
            </div>
            <div className="interest">
                <div className="title">Robotics</div>
                <div className="subTitle">- Motor</div>
                <div className="icon"><ResearchInterestIcon type="robotics-head" /> - head</div>
                <div className="icon"><ResearchInterestIcon type="robotics-arm" /> - arm</div>
                <div className="icon"><ResearchInterestIcon type="robotics-hand" /> - hand</div>
                <div className="icon"><ResearchInterestIcon type="robotics-locomotion" /> - locomotion</div>
                <div className="icon"><ResearchInterestIcon type="robotics-whole-body" /> - whole body movement</div>
                <div className="subTitle">- Sensory</div>
                <div className="icon"><ResearchInterestIcon type="robotics-visual" /> - visual</div>
                <div className="icon"><ResearchInterestIcon type="robotics-auditory" /> - auditory</div>
                <div className="icon"><ResearchInterestIcon type="robotics-somatosensory" /> - somatosensory</div>
                <div className="subTitle">- BCI</div>
                <div className="icon"><ResearchInterestIcon type="bci" /> - BCI</div>
            </div>
        </div>
    </div>;
}

export default BMIResearchSitesInterest;