import React from 'react';
import { Link } from 'react-router-dom';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import { MultiLang } from '../config';
import Functions from '../functions';

interface Props {
    lang: MultiLang;
}

const BMIMaterials: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return <>
        <NoticeSiteHasBeenArchived lang={lang} />
        <h1>{Functions.mlang('[en]Materials[/en][ja]BMI関連データ集[/ja]', lang)}</h1>
        <p>
            {Functions.mlang('[en]Links to BMI-related materials (Movie, Picture, Document, Data, Program) on the internet are listed.You can easily find materials of your interest since each material is classified into research field.[/en][ja]インターネット上にあるBMIに関連した資料(動画や画像、文書、データ、プログラム)へのリンクの一覧です。各資料は検索分野で分類されており、関心のある資料を容易に見つけることが可能です。[/ja]', lang)}
       </p>
        <div className="BMIMaterials">
            <ul>
                <li><Link to="/materials/movie">Movie</Link></li>
                <li><Link to="/materials/picture">Picture</Link></li>
                <li><Link to="/materials/document">Document</Link></li>
                <li><Link to="/materials/data">Data</Link></li>
                <li><Link to="/materials/program">Program</Link></li>
            </ul>
        </div>
    </>;
}

export default BMIMaterials;