import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import ResearchInterestIcon from './lib/ResearchInterestIcon';
import ResarchSiteUtil from './lib/ResearchSitesUtil';

interface Props {
    lang: MultiLang;
    type: number;
}

const BMIResearchSitesResearcher: React.FC<Props> = (props: Props) => {
    const { lang, type } = props;
    const title = Functions.mlang('[en]Search by the researcher[/en][ja]研究者から検索[/ja]', lang);

    const keys = ['A-G', 'H-N', 'O-U', 'V-Z'];
    if (type < 0 || keys.length <= type) {
        return <PageNotFound lang={lang} />;
    }
    const key = keys[type];
    const researchers = ResarchSiteUtil.getResearchersByName(key);
    let ch: string = '';
    return <div className="BMIResearchSitesResearcher">
        <NoticeSiteHasBeenArchived lang={lang} />
        <h1>{Functions.mlang('[en]Research Sites[/en][ja]BMI関連研究室検索[/ja]', lang)}</h1>
        <Link to="/research">TOP</Link>
        <h2>{title}</h2>
        <div className="navi">
            {keys.map((key_, index) => {
                const url = '/research/researcher/' + (index === 0 ? '' : key_.toLowerCase());
                const title = key_ === key ? key_ : <Link to={url}>{key_}</Link>;
                return <Fragment key={index}>
                    {index > 0 && ' / '}
                    <span>{title}</span>
                </Fragment>;
            })}
        </div>
        <table>
            <tbody>
                <tr>
                    <th>▼ Researcher</th>
                    <th style={{ width: '160px' }}>▼ Institute</th>
                    <th style={{ width: '160px' }}>▼ Department</th>
                    <th style={{ width: '150px' }}>▼ Categroy</th>
                    <th style={{ width: '100px' }}>▼ Link</th>
                </tr>
                {researchers.map((researcher, index) => {
                    const institute = researcher.link !== '' ? <Link to={'/research/location/' + researcher.link}>{researcher.institute}</Link> : researcher.institute;
                    const ch_ = researcher.name.substring(0, 1).toUpperCase();
                    const showHead = ch_ !== ch;
                    ch = ch_;
                    return <Fragment key={index}>
                        {showHead && <tr><th colSpan={5}>{ch + '-'}</th></tr>}
                        <tr>
                            <td>{researcher.name}</td>
                            <td>{institute}</td>
                            <td>{researcher.department}</td>
                            <td className="center">{researcher.interest.map((type, index) => {
                                return <ResearchInterestIcon key={index} type={type} />;
                            })}</td>
                            <td className="center">
                                <a href={researcher.url} target="_blank" rel="noopener noreferrer">Web</a>
                            </td>
                        </tr>
                    </Fragment>;
                })}
            </tbody>
        </table>
    </div>;
}

export default BMIResearchSitesResearcher;