import React from 'react';
import LinkImage from '../common/lib/LinkImage';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import { MultiLang } from '../config';
import Functions from '../functions';
import imageSearchInterest from './assets/images/search-interest.gif';
import imageSearchLocation from './assets/images/search-location.gif';
import imageSearchResearcher from './assets/images/search-researcher.gif';

interface Props {
    lang: MultiLang;
}

const BMIResearchSitesIndex: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const searchByLocation = Functions.mlang('[en]Search by the location[/en][ja]地図から検索[/ja]', lang);
    const searchByInterest = Functions.mlang('[en]Search by the field of interest[/en][ja]分野から検索[/ja]', lang);
    const searchByResearcher = Functions.mlang('[en]Search by the researcher[/en][ja]研究者から検索[/ja]', lang);
    return <div className="BMIResearchSites">
        <NoticeSiteHasBeenArchived lang={lang} />
        <h1>{Functions.mlang('[en]Research Sites[/en][ja]BMI関連研究室検索[/ja]', lang)}</h1>
        <p>
            {Functions.mlang('[en]185 BMI-related research sites in the world (university, institute and company) are registered.The research site can be searched either by the location or the field of interest.[/en][ja]185もの大学や研究機関、企業のBMIに関連したホームページが登録されています。研究室検索では、地図をクリックすることで地域または分野から検索することが可能です。[/ja]', lang)}
        </p>
        <div className="clearfix">
            <div className="searchby location">
                <div className="title">{searchByLocation}</div>
                <LinkImage image={imageSearchLocation} title={searchByLocation} url="/research/location" />
            </div>
            <div className="searchby interest">
                <div className="title">{searchByInterest}</div>
                <LinkImage image={imageSearchInterest} title={searchByInterest} url="/research/interest" />
            </div>
        </div>
        <div className="searchby researcher">
            <div className="title">{searchByResearcher}</div>
            <LinkImage image={imageSearchResearcher} title={searchByResearcher} url="/research/researcher" />
        </div>
    </div>;
}

export default BMIResearchSitesIndex;