import loki from 'lokijs';
import pictureJson from '../assets/materials-pictures.json';
import treeJson from '../assets/materials-tree.json';
import materialsJson from '../assets/materials.json';

export interface MaterialData {
    facility: string;
    laboratory: string;
    category: string[];
    link: string;
    type: string;
}

export interface MaterialTreeData {
    id: number;
    name: string;
    title: string;
    pid: number;
    weight: number;
}

export interface MaterialPictureData {
    type: string;
    images: {
        title: string;
        link: string;
        file: string;
    }[];
}

const materialsData = materialsJson as MaterialData[];
const treeData = treeJson as MaterialTreeData[];
const pictureData = pictureJson as MaterialPictureData[];


class MaterialsUtil {

    private database: loki;
    private materials: Collection<MaterialData>;
    private tree: Collection<MaterialTreeData>;
    private pictures: Collection<MaterialPictureData>;

    constructor() {
        this.database = new loki('materials');
        this.materials = this.database.addCollection('materials');
        this.tree = this.database.addCollection('tree');
        this.pictures = this.database.addCollection('pictures');
        materialsData.forEach((material: MaterialData) => {
            this.materials.insert(material)
        });
        treeData.forEach((node: MaterialTreeData) => {
            this.tree.insert(node)
        });
        pictureData.forEach((picture: MaterialPictureData) => {
            this.pictures.insert(picture)
        });
    }

    getMaterials(type: string): MaterialData[] {
        const filter = { type: { '$eq': type } };
        return this.materials.chain().find(filter).simplesort('facility').data();
    }

    getChildTreeNodes(pid: number): MaterialTreeData[] {
        const filter = { pid: { '$eq': pid } };
        return this.tree.chain().find(filter).simplesort('weight').data();
    }

    getParentTreeNodes(name: string): MaterialTreeData[] {
        const parents:MaterialTreeData[] = [];
        const loop = (pid: number) => {
            const filter = { id: { '$eq': pid } };
            const node = this.tree.findOne(filter);
            if (node !== null) {
                loop(node.pid);
                parents.push(node);
            }
        }
        const filter = { name: { '$eq': name } };
        const node = this.tree.findOne(filter);
        if (node !== null) {
            loop(node.pid);
            parents.push(node);
        }
        return parents;
    }

    getPicture(type: string): MaterialPictureData | null {
        const filter = {type: {'$eq': type}};
        return this.pictures.findOne(filter);
    }
}

export default new MaterialsUtil();
