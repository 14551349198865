import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import ResearchInterestIcon from './lib/ResearchInterestIcon';
import ResearchSitesUtil from './lib/ResearchSitesUtil';

interface Props {
    lang: MultiLang;
    type: string;
}

const BMIResearchSitesInterestSearch: React.FC<Props> = (props: Props) => {
    const { lang, type } = props;
    const subTitle = Functions.mlang('[en]Search by the field of interest[/en][ja]分野から検索[/ja]', lang);
    const interest = ResearchSitesUtil.getInterest(type);
    if (interest === null) {
        return <PageNotFound lang={lang} />;
    }
    const categories = ResearchSitesUtil.getCategories(interest.category);
    const researchers = ResearchSitesUtil.getResearchersByInterest(interest.key);
    return <div className="BMIResearchSitesResearcher">
        <NoticeSiteHasBeenArchived lang={lang} />
        <h1>{Functions.mlang('[en]Research Sites[/en][ja]BMI関連研究室検索[/ja]', lang)}</h1>
        <Link to="/research">TOP</Link> &raquo; <Link to="/research/interest">{subTitle}</Link>
        <h2>{subTitle} : {Functions.mlang('[en]Results[/en][ja]検索結果[/ja]', lang)}</h2>
        <div className="navi">
            {categories.map((category, index) => {
                return <Fragment key={index}>
                    {index !== 0 && <> &raquo; </>}
                    {category.title}
                </Fragment>;
            })} &raquo; <strong>{interest.title}</strong>
        </div>
        <table>
            <tbody>
                <tr>
                    <th>▼ Researcher</th>
                    <th style={{ width: '160px' }}>▼ Institute</th>
                    <th style={{ width: '160px' }}>▼ Department</th>
                    <th style={{ width: '150px' }}>▼ Category</th>
                    <th style={{ width: '100px' }}>▼ Link</th>
                </tr>
                {researchers.map((researcher, index) => {
                    const institute = researcher.link !== '' ? <Link to={'/research/location/' + researcher.link}>{researcher.institute}</Link> : researcher.institute;
                    return <tr key={index}>
                        <td>{researcher.name}</td>
                        <td>{institute}</td>
                        <td>{researcher.department}</td>
                        <td className="center">{researcher.interest.map((type, index) => {
                            return <ResearchInterestIcon key={index} type={type} />;
                        })}</td>
                        <td className="center">
                            <a href={researcher.url} target="_blank" rel="noopener noreferrer">Web</a>
                        </td>
                    </tr>;
                })}
            </tbody>
        </table>

    </div>;
}

export default BMIResearchSitesInterestSearch;