import React from 'react';
import { MultiLang } from '../config';

interface Props {
    lang: MultiLang;
}

const Footer = (props: Props) => {
    return (
        <footer id="footer">
            <div className="copyright">Copyright (C) 2018 Neuroinformatics Unit, RIKEN Center for Brain Science</div>
        </footer>
    );
}

export default Footer;
