import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import { MultiLang } from '../config';
import Functions from '../functions';
import MaterialsUtil from './lib/MaterialsUtil';
import MaterialsTree from './lib/MaterialsTree';
import PageNotFound from '../common/lib/PageNotFound';

interface Props {
    lang: MultiLang;
    type: string;
}

const BMIMaterialsPicture: React.FC<Props> = (props: Props) => {
    const { lang, type } = props;
    const parents = type === '' ? [] : MaterialsUtil.getParentTreeNodes(type);
    if (type !== '' && parents.length === 0) {
        return <PageNotFound lang={lang} />;
    }
    const picture = MaterialsUtil.getPicture(type);
    return <div className="BMIMaterials">
        <NoticeSiteHasBeenArchived lang={lang} />
        <h1>{Functions.mlang('[en]Materials[/en][ja]BMI関連データ集[/ja]', lang)}</h1>
        <div className="navi">
            <Link to="/materials/">Materials</Link> &raquo; <strong>Picture</strong>
        </div>
        <h2>Choose the Category of Picture</h2>
        <div className="clearfix">
            <div className="treePanel">
                <MaterialsTree lang={lang} selectedKey={type} />
            </div>
            <div className="picturePanel">
                <div className="index">
                    {parents.map((parent, index) => {
                        const cname = parents.length - 1 === index ? 'node' : 'parent';
                        return <Fragment key={parent.name}>
                            {index > 0 && <> &raquo; </>}
                            <span className={cname}>{parent.title}</span>
                        </Fragment>
                    })}
                </div>
                {picture === null ? <p className="nopictures">No Pictures Found</p> :
                    <div>

                        {picture.images.map((image, index) => {
                            const key = image.file + index;
                            const src = '/images/materials/' + image.file;
                            return <div key={key} className="picture">
                                <a href={image.link} target="_blank" rel="noopener noreferrer">
                                    <img src={src} alt={image.title} title={image.title} />
                                </a>
                            </div>
                        })}
                        {picture.images.length % 2 !== 0 && <div className="picture"><span className="hidden">padding</span></div>}
                    </div>
                }
            </div>

        </div>
        <div className="back">
            <Link to="/materials/">back</Link>
        </div>
    </div>;
}

export default BMIMaterialsPicture;