import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { MultiLang } from '../config';
import BMIColumn from '../custom/BMIColumn';
import BMICredits from '../custom/BMICredits';
import BMIIndex from '../custom/BMIIndex';
import BMIMaterials from '../custom/BMIMaterials';
import BMIOriginalContents from '../custom/BMIOriginalContents';
import BMIPaper from '../custom/BMIPaper';
import BMIPressRelease from '../custom/BMIPressRelease';
import BMIResearchSitesIndex from '../custom/BMIResearchSitesIndex';
import BMIResearchSitesInterest from '../custom/BMIResearchSitesInterest';
import BMIResearchSitesInterestSearch from '../custom/BMIResearchSitesInterestSearch';
import BMIResearchSitesLocation from '../custom/BMIResearchSitesLocation';
import BMIResearchSitesResearcher from '../custom/BMIResearchSitesResearcher';
import BMIStaticPage from '../custom/BMIStaticPage';
import { ResearchSiteRegion } from '../custom/lib/ResearchSitesUtil';
import PageNotFound from './lib/PageNotFound';
import BMIMaterialsList from '../custom/BMIMaterialsList';
import BMIMaterialsPicture from '../custom/BMIMaterialsPicture';

interface Props {
    lang: MultiLang;
}

interface PageParam {
    name: string;
}
interface PageProps extends RouteComponentProps<PageParam> {
}

const MainContent = (props: Props) => {
    const { lang } = props;
    return (
        <div className="mainContent">
            <Switch>
                <Route exact path="/" render={() => {
                    return <BMIIndex lang={lang} />;
                }} />
                <Route exact path="/paper" render={() => {
                    return <BMIPaper lang={lang} />;
                }} />
                <Route exact path="/original" render={() => {
                    return <BMIOriginalContents lang={lang} />;
                }} />
                <Route exact path="/press" render={() => {
                    return <BMIPressRelease lang={lang} />;
                }} />
                <Route exact path="/research" render={() => {
                    return <BMIResearchSitesIndex lang={lang} />;
                }} />
                <Route exact path="/research/location" render={() => {
                    return <BMIResearchSitesLocation lang={lang} />;
                }} />
                <Route exact path="/research/location/:region" render={(props: RouteComponentProps<{ region: string }>) => {
                    const region = props.match.params.region || '';
                    const regions = ['east-asia', 'europe', 'japan', 'middle-east', 'north-america', 'oceania'];
                    if (!regions.includes(region)) {
                        return <PageNotFound lang={lang} />;
                    }
                    return <BMIResearchSitesLocation lang={lang} region={region as ResearchSiteRegion} />;
                }} />
                <Route exact path="/research/location/:region/:id" render={(props: RouteComponentProps<{ region: string, id: string }>) => {
                    const { region, id } = props.match.params;
                    const regions = ['east-asia', 'europe', 'japan', 'middle-east', 'north-america', 'oceania'];
                    if (typeof region === 'undefined' || !regions.includes(region)) {
                        return <PageNotFound lang={lang} />;
                    }
                    if (typeof region === 'undefined' || id.match(/^\d+$/) === null) {
                        return <PageNotFound lang={lang} />;
                    }
                    const siteId = parseInt(id, 10);
                    return <BMIResearchSitesLocation lang={lang} region={region as ResearchSiteRegion} siteId={siteId} />;
                }} />
                <Route exact path="/research/interest" render={() => {
                    return <BMIResearchSitesInterest lang={lang} />;
                }} />
                <Route exact path="/research/interest/:type" render={(props: RouteComponentProps<{ type: string }>) => {
                    const type = props.match.params.type || '';
                    return <BMIResearchSitesInterestSearch lang={lang} type={type} />;
                }} />
                <Route exact path="/research/researcher" render={() => {
                    return <BMIResearchSitesResearcher lang={lang} type={0} />;
                }} />
                <Route exact path="/research/researcher/a-g" render={() => {
                    return <BMIResearchSitesResearcher lang={lang} type={0} />;
                }} />
                <Route exact path="/research/researcher/h-n" render={() => {
                    return <BMIResearchSitesResearcher lang={lang} type={1} />;
                }} />
                <Route exact path="/research/researcher/o-u" render={() => {
                    return <BMIResearchSitesResearcher lang={lang} type={2} />;
                }} />
                <Route exact path="/research/researcher/v-z" render={() => {
                    return <BMIResearchSitesResearcher lang={lang} type={3} />;
                }} />
                <Route exact path="/materials" render={() => {
                    return <BMIMaterials lang={lang} />;
                }} />
                <Route exact path="/materials/picture" render={() => {
                    return <BMIMaterialsPicture lang={lang} type='' />;
                }} />
                <Route exact path="/materials/picture/:type" render={(props: RouteComponentProps<{ type: string }>) => {
                    const type = props.match.params.type || '';
                    return <BMIMaterialsPicture lang={lang} type={type} />;
                }} />
                <Route exact path="/materials/:type" render={(props: RouteComponentProps<{ type: string }>) => {
                    const type = props.match.params.type || '';
                    return <BMIMaterialsList lang={lang} type={type} />;
                }} />
                <Route exact path="/column" render={() => {
                    return <BMIColumn lang={lang} />;
                }} />
                <Route exact path="/instructions" render={() => {
                    return <BMICredits lang={lang} name="instructions" />;
                }} />
                <Route exact path="/copyrights" render={() => {
                    return <BMICredits lang={lang} name="copyrights" />;
                }} />
                <Route exact path="/privacy" render={() => {
                    return <BMICredits lang={lang} name="privacy" />;
                }} />
                <Route exact path="/policy" render={() => {
                    return <BMICredits lang={lang} name="policy" />;
                }} />
                <Route exact path="/aboutus" render={() => {
                    return <BMICredits lang={lang} name="aboutus" />;
                }} />
                <Route exact path="/:name" render={(props: PageProps) => {
                    const name = props.match.params.name || '';
                    return <BMIStaticPage lang={lang} name={name} />;
                }} />
                <Route render={() => <PageNotFound lang={lang} />} />
            </Switch>
        </div>
    );
}

export default MainContent;
