import React from 'react';
import { MultiLang } from '../config';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import Functions from '../functions';
import imageClick from './assets/images/click.gif';
import imageCapture1 from './assets/images/paper-capture1.jpg';
import imageCapture2 from './assets/images/paper-capture2.jpg';
import imageCapture3 from './assets/images/paper-capture3.jpg';

interface Props {
    lang: MultiLang;
}

const BMIPaper: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return <>
        <NoticeSiteHasBeenArchived lang={lang} />
        <h1>{Functions.mlang('[en]Paper (XooNIps Mode)[/en][ja]BMI関連論文 (XooNIps Mode)[/ja]', lang)}</h1>
        <p>
            {Functions.mlang('[en]More than 3500 BMI-related papers are registered. Each paper has original tags, for example, recording method and subject, for easy searching.[/en][ja]3500本以上のBMIに関連した論文が登録されています。各論文には、レコーディング方法やサブジェクト等のタグがついており、検索が容易となっています。[/ja]', lang)}
        </p>
        <p>
            {Functions.mlang('[en]You can access to the database and find the paper of your interest by the following procedures.[/en][ja]データベースにアクセスし、以下の手順に従って関心のある論文を探すことができます。[/ja]', lang)}
       </p>
        <ol className="BMIPaper">
            <li>
            {Functions.mlang('[en]Click the following icon in order to access the database.[/en][ja]下のアイコンをクリックして、データベースにアクセスしてください。[/ja]', lang)}
                 <div className="middle">
                    <a href="/researchers/database">
                        <img src={imageClick} alt="click" />
                        <strong style={{ fontSize: '1.424em' }}>{Functions.mlang('[en]Database of BMI-related papers[/en][ja]BMI関連論文のデータベース[/ja]', lang)}</strong>
                    </a>
                </div>
            </li>
            <li>
                {Functions.mlang('[en]Choose the research field of your interest from the index tree.[/en][ja]Index Treeから関心のある分野を選んでください。[/ja]', lang)}
                <div className="capture">
                    <img src={imageCapture1} alt="screen capture 1" />
                </div>
            </li>
            <li>
                {Functions.mlang('[en]Find the paper of your interest and click it.[/en][ja]論文を選び、クリックしてください。[/ja]', lang)}
                 <div className="capture">
                    <img src={imageCapture2} alt="screen capture 2" />
                </div>
            </li>
            <li>
                {Functions.mlang('[en]You can see the detail of the paper.[/en][ja]論文の詳細を見ることができます。[/ja]', lang)}
                <div className="capture">
                    <img src={imageCapture3} alt="screen capture 3" />
                </div>
            </li>
        </ol>

    </>;
}

export default BMIPaper;