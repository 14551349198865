import loki from 'lokijs';
import researchersJson from '../assets/researchers.json';
import sitesJson from '../assets/sites.json';

export type ResearchInterestKey = 'human' | 'monkey' | 'rodent' | 'other-animals' | 'physiology-arm' | 'physiology-hand' | 'physiology-locomotion' | 'physiology-whole-body' | 'physiology-navigation' | 'physiology-visual' | 'physiology-auditory' | 'physiology-somatosensory' | 'unit-recording' | 'emg' | 'eeg' | 'nirs' | 'fmri' | 'ecog' | 'pet' | 'meg' | 'kinematics' | 'control-theories' | 'robotics-head' | 'robotics-arm' | 'robotics-hand' | 'robotics-locomotion' | 'robotics-whole-body' | 'robotics-visual' | 'robotics-auditory' | 'robotics-somatosensory' | 'bci';
export type ResearchSiteRegion = 'east-asia' | 'europe' | 'japan' | 'middle-east' | 'north-america' | 'oceania';

export interface ResearchCategory {
    key: string;
    title: string;
    parent: string;
    weight: number;
};
export interface ResearchInterest {
    key: ResearchInterestKey;
    title: string;
    category: string;
    weight: number;
};
export interface ResearcherData {
    name: string;
    institute: string;
    department: string;
    interest: ResearchInterestKey[];
    url: string;
    link: string;
};
export interface ResearchSiteData {
    name: string;
    laboratories: {
        name: string;
        url: string;
        division: string;
        address: string;
        phone: string;
        persons: {
            name: string;
            title: string;
            ids: {
                type: string;
                id: string;
            }[];
        }[];
        items: {
            item_id: number;
            title: string;
        }[];
        interests: ResearchInterestKey[];
    }[];
    link: string;
    region: ResearchSiteRegion;
    country: string;
    map: {
        shape: string;
        coords: string;
    }[];
};

const researchersData = researchersJson as ResearcherData[];
const sitesData = sitesJson as ResearchSiteData[];
const categoriesData: ResearchCategory[] = [
    { key: 's', title: 'Subjects', parent: '', weight: 0 },
    { key: 'p', title: 'Physiology', parent: '', weight: 1 },
    { key: 'p-m', title: 'Motor', parent: 'p', weight: 0 },
    { key: 'p-s', title: 'Sensory', parent: 'p', weight: 1 },
    { key: 't', title: 'Theories and Models', parent: '', weight: 2 },
    { key: 't-s', title: 'Signal Processing', parent: 't', weight: 0 },
    { key: 'r', title: 'Robotics', parent: '', weight: 2 },
    { key: 'r-m', title: 'Motor', parent: 'r', weight: 0 },
    { key: 'r-s', title: 'Sensory', parent: 'r', weight: 1 },
    { key: 'r-b', title: 'BCI', parent: 'r', weight: 2 },
];
const interestsData: ResearchInterest[] = [
    { key: 'human', title: 'human', category: 's', weight: 0 },
    { key: 'monkey', title: 'monkey', category: 's', weight: 1 },
    { key: 'rodent', title: 'rodent', category: 's', weight: 2 },
    { key: 'other-animals', title: 'other animals', category: 's', weight: 3 },
    { key: 'physiology-arm', title: 'arm', category: 'p-m', weight: 0 },
    { key: 'physiology-hand', title: 'hand', category: 'p-m', weight: 1 },
    { key: 'physiology-locomotion', title: 'locomotion', category: 'p-m', weight: 2 },
    { key: 'physiology-whole-body', title: 'whole body movement', category: 'p-m', weight: 3 },
    { key: 'physiology-navigation', title: 'navigation', category: 'p-m', weight: 4 },
    { key: 'physiology-visual', title: 'visual', category: 'p-s', weight: 0 },
    { key: 'physiology-auditory', title: 'audtory', category: 'p-s', weight: 1 },
    { key: 'physiology-somatosensory', title: 'somatosensory', category: 'p-s', weight: 2 },
    { key: 'unit-recording', title: 'unit recording', category: 't-s', weight: 1 },
    { key: 'emg', title: 'EMG', category: 't-s', weight: 0 },
    { key: 'eeg', title: 'EEG', category: 't-s', weight: 1 },
    { key: 'nirs', title: 'NIRS', category: 't-s', weight: 2 },
    { key: 'fmri', title: 'fMRI', category: 't-s', weight: 3 },
    { key: 'ecog', title: 'ECoG', category: 't-s', weight: 4 },
    { key: 'pet', title: 'PET', category: 't-s', weight: 5 },
    { key: 'meg', title: 'MEG', category: 't-s', weight: 6 },
    { key: 'kinematics', title: 'kinematics', category: 't-s', weight: 7 },
    { key: 'control-theories', title: 'control theories', category: 't-s', weight: 8 },
    { key: 'robotics-head', title: 'head', category: 'r-m', weight: 0 },
    { key: 'robotics-arm', title: 'arm', category: 'r-m', weight: 1 },
    { key: 'robotics-hand', title: 'hand', category: 'r-m', weight: 2 },
    { key: 'robotics-locomotion', title: 'locomotion', category: 'r-m', weight: 3 },
    { key: 'robotics-whole-body', title: 'whole body movement', category: 'r-m', weight: 4 },
    { key: 'robotics-visual', title: 'visual', category: 'r-s', weight: 0 },
    { key: 'robotics-auditory', title: 'auditory', category: 'r-s', weight: 1 },
    { key: 'robotics-somatosensory', title: 'somatosensory', category: 'r-s', weight: 2 },
    { key: 'bci', title: 'BCI', category: 'r-b', weight: 0 },
];

class ResearchSitesUtil {

    private database: loki;
    private researchers: Collection<ResearcherData>;
    private sites: Collection<ResearchSiteData>;
    private interests: Collection<ResearchInterest>;
    private categories: Collection<ResearchCategory>;

    constructor() {
        this.database = new loki('research-sites');
        this.researchers = this.database.addCollection('researchers');
        this.sites = this.database.addCollection('sites');
        this.interests = this.database.addCollection('interests');
        this.categories = this.database.addCollection('categories');
        researchersData.forEach((researcher: ResearcherData) => {
            this.researchers.insert(researcher);
        });
        sitesData.forEach((location: ResearchSiteData) => {
            this.sites.insert(location);
        });
        interestsData.forEach((interest: ResearchInterest) => {
            this.interests.insert(interest);
        });
        categoriesData.forEach((category: ResearchCategory) => {
            this.categories.insert(category);
        });
    }

    getInterest(key: string): ResearchInterest | null {
        const filter = { key: { '$eq': key } };
        return this.interests.findOne(filter);
    }

    getCategories(key: string): ResearchCategory[] {
        let categories: ResearchCategory[] = [];
        const search = (key: string) => {
            const filter = { key: { '$eq': key } };
            return this.categories.findOne(filter);
        };
        let key_ = key;
        while (key !== '') {
            const category = search(key_);
            if (category === null) {
                break;
            }
            categories.unshift(category);
            key_ = category.parent;
        }
        return categories;
    }

    getResearchersByName(name: string): ResearcherData[] {
        const filter = { name: { '$regex': ['^[' + name + ']', 'i'] } };
        return this.researchers.chain().find(filter).simplesort('name').data();
    }

    getResearchersByInterest(interest: string): ResearcherData[] {
        const filter = { interest: { '$contains': interest } };
        return this.researchers.chain().find(filter).simplesort('name').data();
    }

    getSites(region: string): ResearchSiteData[] {
        const filter = { region: { '$eq': region } };
        const sort = (a: ResearchSiteData, b: ResearchSiteData) => {
            if (a.country > b.country) {
                return 1;
            } else if (a.country < b.country) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            } else if (a.name < b.name) {
                return -1;
            }
            return 0;
        }
        return this.sites.chain().find(filter).sort(sort).data();
    }

    getSite(region: string, siteId: number): ResearchSiteData | null {
        const filter = { link: { '$eq': region + '/' + siteId } };
        return this.sites.findOne(filter);
    }
}

export default new ResearchSitesUtil();