import React from 'react';
import { Link } from 'react-router-dom';
import imageJapanNode from '../common/assets/theme/images/icon_jnode.gif';
import imageMenuColumn from '../common/assets/theme/images/menu_column.gif';
import imageMenuMaterials from '../common/assets/theme/images/menu_materials.gif';
import imageMenuOriginalContents from '../common/assets/theme/images/menu_original-contents.gif';
import imageMenuPaper from '../common/assets/theme/images/menu_paper.gif';
import imageMenuPressRelease from '../common/assets/theme/images/menu_press-release.gif';
import imageMenuResearchSites from '../common/assets/theme/images/menu_research-sites.gif';
import Config, { MultiLang } from '../config';
import Functions from '../functions';
import LangFlag from './lib/LangFlag';
import LinkImage from './lib/LinkImage';

interface Props {
    lang: MultiLang;
}

const Header: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const title = Functions.mlang(Config.SITE_TITLE, lang);
    return (
        <div id="header">
            <div className="clearfix">
                <div className="left">
                    <Link to="/"><span className="hidden">{title}</span></Link>
                </div>
                <div className="right">
                    <div className="navi clearfix">
                        <div className="home">
                            <Link to="/">HOME</Link>
                        </div>
                        <div className="contact">
                            <Link to="/aboutus">ABOUT US</Link>
                        </div>
                        <div className="language">
                            <LangFlag lang="en" />
                            <LangFlag lang="ja" />
                        </div>
                    </div>
                    <div>
                        <LinkImage image={imageJapanNode} title="INCF Japan Node" url={'https://www.neuroinf.jp/'} />
                    </div>
                </div>
            </div>
            <div className="menu">
                <LinkImage image={imageMenuPaper} title={'Paper'} url="/paper" />
                <LinkImage image={imageMenuOriginalContents} title={'Original Contents'} url="/original" />
                <LinkImage image={imageMenuPressRelease} title={'Press Release'} url="/press" />
                <LinkImage image={imageMenuResearchSites} title={'Research Sites'} url="/research" />
                <LinkImage image={imageMenuMaterials} title={'Materials'} url="/materials" />
                <LinkImage image={imageMenuColumn} title={'Column'} url="/column" />
            </div>
        </div>
    );
};

export default Header;