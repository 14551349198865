import React, { CSSProperties } from 'react';

interface Props {
    style?: CSSProperties;
    className?: string;
}

class DraggablePanel extends React.Component<Props> {
    private divRef: React.RefObject<HTMLDivElement>;
    private clientPos: { x: number, y: number } | null;

    constructor(props: Props) {
        super(props);
        this.divRef = React.createRef<HTMLDivElement>();
        this.clientPos = null;
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    unsetPosition() {
        const div = this.divRef && this.divRef.current;
        if (div !== null) {
            div.style.cursor = 'auto';
        }
        this.clientPos = null;
    }

    handleMouseDown(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        const div = this.divRef && this.divRef.current;
        if (div !== null) {
            div.style.cursor = 'move';
            this.clientPos = { x: e.clientX, y: e.clientY };
        }
        e.stopPropagation();
        e.preventDefault();
    }

    handleMouseUp(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        this.unsetPosition();
        e.stopPropagation();
        e.preventDefault();
    }

    handleMouseLeave(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        this.unsetPosition();
        e.stopPropagation();
        e.preventDefault();
    }

    handleMouseMove(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        const div = this.divRef && this.divRef.current;
        if (div !== null && this.clientPos !== null) {
            div.scrollLeft += this.clientPos.x - e.clientX;
            div.scrollTop += this.clientPos.y - e.clientY;
            this.clientPos = { x: e.clientX, y: e.clientY };
        }
        e.stopPropagation();
        e.preventDefault();
    }

    render() {
        const { style, className } = this.props;
        return <div className={className} style={style} ref={this.divRef} onMouseDown={this.handleMouseDown} onMouseUp={this.handleMouseUp} onMouseMove={this.handleMouseMove} onMouseLeave={this.handleMouseLeave}>
            {this.props.children}
        </div>;
    }
}

export default DraggablePanel;