import React from 'react';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import PageNotFound from '../common/lib/PageNotFound';
import XoopsCode from '../common/lib/XoopsCode';
import { MultiLang } from '../config';
import creditsJson from './assets/credits.json';

interface creditsJsonData {
    [name: string]: {
        en: {
            title: string;
            content: string;
        };
        ja: {
            title: string;
            content: string;
        };
    };
}

interface Props {
    lang: MultiLang;
    name: string;
}

const BMICredits: React.FC<Props> = (props: Props) => {
    const { lang, name } = props;
    const creditsData = creditsJson as creditsJsonData;
    if (!(name in creditsData)) {
        return <PageNotFound lang={lang} />;
    }
    const page = creditsData[name][lang];
    return (
        <>
            <NoticeSiteHasBeenArchived lang={lang} />
            <h1>{page.title}</h1>
            <div className="BMICredits">
                <XoopsCode lang={lang} text={page.content} dohtml={true} />
            </div>
        </>
    );

}

export default BMICredits;