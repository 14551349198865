import React, { Component } from 'react';
import { Modal } from 'react-overlays';
import { RouteComponentProps, withRouter } from 'react-router';
import DraggablePanel from '../../common/lib/DraggablePanel';
import { MultiLang } from '../../config';
import imageMapEastAsia from '../assets/images/map_east-asia.jpg';
import imageMapEurope from '../assets/images/map_europe.jpg';
import imageMapJapan from '../assets/images/map_japan.jpg';
import imageMapMiddleEast from '../assets/images/map_middle-east.png';
import imageMapNorthAmerica from '../assets/images/map_north-america.jpg';
import imageMapOceania from '../assets/images/map_oceania.png';
import iconMenu from '../assets/images/menu.png';
import ResearchLocationMapList from './ResearchLocationMapList';
import ResearchLocationMapSite from './ResearchLocationMapSite';
import ResearchSitesUtil, { ResearchSiteData, ResearchSiteRegion } from './ResearchSitesUtil';

interface Props extends RouteComponentProps {
    lang: MultiLang;
    region: ResearchSiteRegion;
    siteId?: number;
}

interface State {
    showList: boolean;
}

const imageMaps = {
    'east-asia': { data: imageMapEastAsia, title: 'East Asia' },
    europe: { data: imageMapEurope, title: 'Europe' },
    japan: { data: imageMapJapan, title: 'Japan' },
    'middle-east': { data: imageMapMiddleEast, title: 'Middle East' },
    'north-america': { data: imageMapNorthAmerica, title: 'North America' },
    oceania: { data: imageMapOceania, title: 'Oceania' }
};

class ResearchLocationMap extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            showList: true,
        };
        this.handleClickArea = this.handleClickArea.bind(this);
        this.handleClickShowList = this.handleClickShowList.bind(this);
        this.handleClickBack = this.handleClickBack.bind(this);
    }

    handleClickArea(e: React.MouseEvent<HTMLAreaElement, MouseEvent>) {
        const { history } = this.props;
        const url = e.currentTarget.getAttribute('href') || '';
        e.preventDefault();
        e.stopPropagation();
        history.push(url);
    }

    handleClickShowList(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        const showList = !this.state.showList;
        e.preventDefault();
        e.stopPropagation();
        this.setState({ showList });
    }

    handleClickBack(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        const { history } = this.props;
        e.preventDefault();
        e.stopPropagation();
        history.push('/research/location/');
    }

    render() {
        const { region, siteId } = this.props;
        const sites = ResearchSitesUtil.getSites(region);
        let site: ResearchSiteData | null = null;
        if (typeof siteId !== 'undefined') {
            site = ResearchSitesUtil.getSite(region, siteId);
            if (site === null) {
                return null;
            }
        }
        const image = imageMaps[region];
        const areas: JSX.Element[] = [];
        sites.forEach((site) => {
            site.map.forEach((area) => {
                const key = area.coords;
                const link = '/research/location/' + site.link;
                areas.push(<area key={key} href={link} alt={site.name} onClick={this.handleClickArea} title={site.name} shape={area.shape} coords={area.coords} />);
            });
        });
        return <Modal className="BMIResearchSitesLocationModal" show={true}>
            <div className="regionMap">
                <DraggablePanel className="map">
                    <img style={{ verticalAlign: 'middle' }} src={image.data} alt={image.title} useMap="#mapdata" />
                    <map name="mapdata">
                        {areas}
                    </map>
                </DraggablePanel>
                {this.state.showList ?
                    <div className="panel siteList show">
                        <div className="close" onClick={this.handleClickShowList}>&times;</div>
                        <ResearchLocationMapList sites={sites} />
                    </div>
                    :
                    <div className="panel siteList hide">
                        <div className="button" onClick={this.handleClickShowList}><img src={iconMenu} alt="menu" /></div>
                    </div>
                }
                <div className="panel back">
                    <div className="button" onClick={this.handleClickBack}>
                        <span className="font-weight-bold">&laquo;</span> back
                    </div>
                </div>
                {site && <ResearchLocationMapSite region={region} site={site} />}
            </div>
        </Modal>;
    }
}

export default withRouter(ResearchLocationMap);