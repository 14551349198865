import axios from 'axios';
import React, { Component } from 'react';
import Loading from '../common/lib/Loading';
import PageNotFound from '../common/lib/PageNotFound';
import XoopsCode from '../common/lib/XoopsCode';
import { MultiLang } from '../config';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';

interface Props {
    lang: MultiLang;
    name: string;
}

interface State {
    loading: boolean;
    content: string | null;
}

class BMIStaticPage extends Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {
            content: null,
            loading: true,
        }
        this.isActive = false;
    }

    componentDidMount() {
        const { name } = this.props;
        this.isActive = true;
        this.updatePage(name);

    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { lang, name } = this.props;
        const prevLang = prevProps.lang;
        const prevName = prevProps.name;
        if (name !== prevName || lang !== prevLang) {
            this.setState({ loading: true, content: null });
            this.updatePage(name);
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    updatePage(name: string) {
        axios.get('/contents/' + name + '.xml').then((response) => {
            if (this.isActive) {
                const content = response.data as string;
                this.setState({ loading: false, content });
            }
        }).catch(e => {
            if (this.isActive) {
                this.setState({ loading: false, content: null });
            }
        });
    }

    render() {
        const { lang } = this.props;
        const { loading, content } = this.state;
        if (loading) {
            return <Loading />;
        }
        if (content === null) {
            return <PageNotFound lang={lang} />;
        }
        return (
            <>
                <NoticeSiteHasBeenArchived lang={lang} />
                <XoopsCode lang={lang} text={content} dohtml={true} />
            </>
        );
    }

}

export default BMIStaticPage;