import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
    type: string;
}

const type2title = (type: string): string => {
    let title = type;
    title = title.replace(/^r-bci$/, 'BCI');
    title = title.replace(/^t-s-emg-eeg$/, 'EMG, EEG');
    title = title.replace(/^p-.-/, 'Physiology: ');
    title = title.replace(/^t-.-/, '');
    title = title.replace(/^r-.-/, 'Robotics: ');
    title = title.replace(/-/, ' ');
    return title;
};

const MaterialsCategoryIcon: React.FC<Props> = (props: Props) => {
    const { type } = props;
    const icon = '/images/materials/icon_' + type + '.gif';
    const url = '/materials/picture/' + type;
    const title = type2title(type);
    return <div style={{ display: 'inline-block', margin: '2px' }}>
        <Link to={url}>
            <img src={icon} alt={title} title={title} />
        </Link>
    </div>;
};

export default MaterialsCategoryIcon;