import React from 'react';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import { MultiLang } from '../config';
import Functions from '../functions';
import imagePhoto from './assets/images/koike-photo.jpg';

interface Props {
    lang: MultiLang;
}

const BMIColumn: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return <>
        <NoticeSiteHasBeenArchived lang={lang} />
        <h1>{Functions.mlang('[en]BMI-related column (Japanese)[/en][ja]BMI関連コラム[/ja]', lang)}</h1>
        <p>
            {Functions.mlang('[en]The columns are written by researchers specialized in BMI.[/en][ja]BMIを専門とする研究者によるコラムがあります。[/ja]', lang)}
        </p>
        <div className="BMIColumn clearfix">
            <div className="left">
                <img src={imagePhoto} alt="Dr. Yasuharu Koike" />
            </div>
            <div className="right">
                <div className="title">
                    <a href="/contents/BCI.pdf" download="BCI.pdf">
                        {Functions.mlang('[en]"Non-Invasive Brain Machine Interface (Brain Computer Interface)"[/en][ja]『非侵襲ブレインマシンインタフェース (Brain Computer Interface)』[/ja]', lang)}
                    </a>
                    <span className="note">(PDF file)</span>
                </div>
                <div className="clearfix">
                    <div className="left">
                        <div>
                            {Functions.mlang('[en]Tokyo Institute of Technology[/en][ja]東京工業大学 ソリューション研究機構[/ja]', lang)}
                            <br />
                            {Functions.mlang('[en]Yasuharu Koike[/en][ja]小池康晴[/ja]', lang)}
                        </div>
                        <ul className="link">
                            <li><a href="http://www.cns.pi.titech.ac.jp/" target="_blank" rel="noopener noreferrer">Web</a></li>
                        </ul>
                    </div>
                    <div className="date right" >
                        2012.09.21
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default BMIColumn;