import React from 'react';
import { Link } from 'react-router-dom';
import iconBci from '../assets/images/interest/icon_bci.gif';
import iconControlTheories from '../assets/images/interest/icon_control-theories.gif';
import iconEcog from '../assets/images/interest/icon_ecog.gif';
import iconEeg from '../assets/images/interest/icon_eeg.gif';
import iconEmg from '../assets/images/interest/icon_emg.gif';
import iconFmri from '../assets/images/interest/icon_fmri.gif';
import iconHuman from '../assets/images/interest/icon_human.gif';
import iconKinematics from '../assets/images/interest/icon_kinematics.gif';
import iconMeg from '../assets/images/interest/icon_meg.gif';
import iconMonkey from '../assets/images/interest/icon_monkey.gif';
import iconNirs from '../assets/images/interest/icon_nirs.gif';
import iconOtherAnimals from '../assets/images/interest/icon_other-animals.gif';
import iconPet from '../assets/images/interest/icon_pet.gif';
import iconPhysiologyArm from '../assets/images/interest/icon_physiology-arm.gif';
import iconPhysiologyAuditory from '../assets/images/interest/icon_physiology-auditory.gif';
import iconPhysiologyHand from '../assets/images/interest/icon_physiology-hand.gif';
import iconPhysiologyLocomotion from '../assets/images/interest/icon_physiology-locomotion.gif';
import iconPhysiologyNavigation from '../assets/images/interest/icon_physiology-navigation.gif';
import iconPhysiologySomatosensory from '../assets/images/interest/icon_physiology-somatosensory.gif';
import iconPhysiologyVisual from '../assets/images/interest/icon_physiology-visual.gif';
import iconPhysiologyWholeBody from '../assets/images/interest/icon_physiology-whole-body.gif';
import iconRoboticsArm from '../assets/images/interest/icon_robotics-arm.gif';
import iconRoboticsAuditory from '../assets/images/interest/icon_robotics-auditory.gif';
import iconRoboticsHand from '../assets/images/interest/icon_robotics-hand.gif';
import iconRoboticsHead from '../assets/images/interest/icon_robotics-head.gif';
import iconRoboticsLocomotion from '../assets/images/interest/icon_robotics-locomotion.gif';
import iconRoboticsSomatosensory from '../assets/images/interest/icon_robotics-somatosensory.gif';
import iconRoboticsVisual from '../assets/images/interest/icon_robotics-visual.gif';
import iconRoboticsWholeBody from '../assets/images/interest/icon_robotics-whole-body.gif';
import iconRodent from '../assets/images/interest/icon_rodent.gif';
import iconUnitRecording from '../assets/images/interest/icon_unit-recording.gif';
import { ResearchInterestKey } from './ResearchSitesUtil';


const InterestIconData = {
    human: {
        icon: iconHuman,
        title: 'human',
    },
    monkey: {
        icon: iconMonkey,
        title: 'monkey',
    },
    rodent: {
        icon: iconRodent,
        title: 'rodent',
    },
    'other-animals': {
        icon: iconOtherAnimals,
        title: 'other animals',
    },
    'physiology-arm': {
        icon: iconPhysiologyArm,
        title: 'physiology - arm',
    },
    'physiology-hand': {
        icon: iconPhysiologyHand,
        title: 'physiology - hand',
    },
    'physiology-locomotion': {
        icon: iconPhysiologyLocomotion,
        title: 'physiology - locomotion',
    },
    'physiology-whole-body': {
        icon: iconPhysiologyWholeBody,
        title: 'physiology - whole body movement',
    },
    'physiology-navigation': {
        icon: iconPhysiologyNavigation,
        title: 'navigation',
    },
    'physiology-visual': {
        icon: iconPhysiologyVisual,
        title: 'physiology - visual',
    },
    'physiology-auditory': {
        icon: iconPhysiologyAuditory,
        title: 'physiology - audtory',
    },
    'physiology-somatosensory': {
        icon: iconPhysiologySomatosensory,
        title: 'physiology - somatosensory',
    },
    'unit-recording': {
        icon: iconUnitRecording,
        title: 'unit recording',
    },
    emg: {
        icon: iconEmg,
        title: 'EMG',
    },
    eeg: {
        icon: iconEeg,
        title: 'EEG',
    },
    nirs: {
        icon: iconNirs,
        title: 'NIRS',
    },
    fmri: {
        icon: iconFmri,
        title: 'fMRI',
    },
    ecog: {
        icon: iconEcog,
        title: 'ECoG',
    },
    pet: {
        icon: iconPet,
        title: 'PET',
    },
    meg: {
        icon: iconMeg,
        title: 'MEG',
    },
    kinematics: {
        icon: iconKinematics,
        title: 'kinematics',
    },
    'control-theories': {
        icon: iconControlTheories,
        title: 'control theories',
    },
    'robotics-head': {
        icon: iconRoboticsHead,
        title: 'robotics - head',
    },
    'robotics-arm': {
        icon: iconRoboticsArm,
        title: 'robotics - arm',
    },
    'robotics-hand': {
        icon: iconRoboticsHand,
        title: 'robotics - hand',
    },
    'robotics-locomotion': {
        icon: iconRoboticsLocomotion,
        title: 'robotics - locomotion',
    },
    'robotics-whole-body': {
        icon: iconRoboticsWholeBody,
        title: 'robotics - whole body movement',
    },
    'robotics-visual': {
        icon: iconRoboticsVisual,
        title: 'robotics - visual',
    },
    'robotics-auditory': {
        icon: iconRoboticsAuditory,
        title: 'robotics - auditory',
    },
    'robotics-somatosensory': {
        icon: iconRoboticsSomatosensory,
        title: 'robotics - somatosensory',
    },
    bci: {
        icon: iconBci,
        title: 'BCI',
    },
};

interface Props {
    type: ResearchInterestKey;
}

const ResearchInterestIcon: React.FC<Props> = (props: Props) => {
    const { type } = props;
    const data = InterestIconData[type];
    const url = '/research/interest/' + type;
    return <div style={{ display: 'inline-block', margin: '2px' }}>
        <Link to={url}>
            <img src={data.icon} alt={data.title} title={data.title} />
        </Link>
    </div>;
};

export default ResearchInterestIcon;