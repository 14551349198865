import React from 'react';
import { MultiLang } from '../config';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import pressJson from './assets/press.json';
import Functions from '../functions';

interface PressJsonData {
    [lang: string]: {
        date: string;
        title: string;
        url: string;
        publisher: {
            name: string;
            url: string;
        }[];
    }[];
}

interface Props {
    lang: MultiLang;
}

const BMIPressRelease: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const pressData = pressJson as PressJsonData;
    const press = pressData[lang];
    return <>
        <NoticeSiteHasBeenArchived lang={lang} />
        <h1>{Functions.mlang('[en]Press Release[/en][ja]プレスリリース[/ja]', lang)}</h1>
        <ul className="BMIPressRelease">
            {press.map((item, index) => {
                const title = item.url === '' ? item.title : <a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}</a>;
                return <li key={index}>
                    <div className="clearfix">
                        <div className="date">{item.date}</div>
                        <div className="body">
                            <div className="title">{title}</div>
                            <ul className="publisher">
                                {item.publisher.map((publisher, index) => {
                                    const name = publisher.url === '' ? publisher.name : <a href={publisher.url} target="_blank" rel="noopener noreferrer">{publisher.name}</a>;
                                    return <li key={index}>{name}</li>;
                                })}
                            </ul>
                        </div>
                    </div>
                </li>;
            })}
        </ul>
    </>;
}

export default BMIPressRelease;