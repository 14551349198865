import React from 'react';
import { Link } from 'react-router-dom';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import MaterialsCategoryIcon from './lib/MaterialsCategoryIcon';
import MaterialsUtil from './lib/MaterialsUtil';

interface Props {
    lang: MultiLang;
    type: string;
}

const BMIMaterialsList: React.FC<Props> = (props: Props) => {
    const { lang, type } = props;
    const name = Functions.pascalCase(type);
    const types = ['movie', 'document', 'data', 'program'];
    if (!types.includes(type)) {
        return <PageNotFound lang={lang} />;
    }
    const materials = MaterialsUtil.getMaterials(type);
    return <div className="BMIMaterials">
        <NoticeSiteHasBeenArchived lang={lang} />
        <h1>{Functions.mlang('[en]Materials[/en][ja]BMI関連データ集[/ja]', lang)}</h1>
        <div className="navi">
            <Link to="/materials/">Materials</Link> &raquo; <strong>{name}</strong>
        </div>
        <h2>Choose the {name}</h2>
        <table>
            <tbody>
                <tr>
                    <th>Facility</th>
                    <th>Laboratory</th>
                    <th>Category</th>
                    <th>{name}</th>
                </tr>
                {materials.map((material) => {
                    const key = material.facility + material.laboratory;
                    return <tr key={key}>
                        <td>{material.facility}</td>
                        <td>{material.laboratory}</td>
                        <td className="text-center">{material.category.map((cat) => {
                            return <MaterialsCategoryIcon key={cat} type={cat} />;
                        })}</td>
                        <td className="text-center">
                            <a href={material.link} target="_blank" rel="noopener noreferrer">Web</a>
                        </td>
                    </tr>
                })}
            </tbody>
        </table>
        <div className="back">
            <Link to="/materials/">back</Link>
        </div>
    </div>;
}

export default BMIMaterialsList;